
import megacomSrc from '../static/img/megacom_logo.webp';
import oSrc from '../static/img/o_logo.webp';
import phoneSrc from '../static/img/phone_logo.webp';
import whatsappSrc from '../static/img/whatsapp_logo.webp';
import crossSrc from '../static/img/cross_icon.webp';

export default {
  name: 'call-button',
  data() {
    return {
      isOpen: false,
      phoneSrc,
      oSrc,
      whatsappSrc,
      megacomSrc,
      crossSrc,
    };
  },
};
