
import snowBg from '~/assets/img/snow-bg.png';
import elsomLogo from '../static/img/elsom.png';

export default {
  name: 'footer-block',
  data() {
    return {
      elsomLogo,
      snowBg,
      year: (new Date()).getFullYear(),
    };
  },
};
