/* eslint-disable no-console */
/* eslint-disable no-alert */
import Vue from 'vue';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import PushMethods from 'razlet-sdk/lib/method/push/push';
import { messaging } from '~/plugins/firebase';
import { onMessage, getToken, deleteToken } from 'firebase/messaging';

const pushSender = new PushMethods();


// SHARED
function setPushToken(ctx, token) {
  ctx.store.commit('setPushToken', token);
}
function setPushRegistered(ctx, isRegistered) {
  ctx.store.commit('setPushRegistered', isRegistered);
}
async function sendRegister(ctx, token, jwtToken) {
  return pushSender.register({token}, jwtToken).then(() => {
    setPushRegistered(ctx, true);
  });
}


// APP
async function removeAppListeners() {
  await PushNotifications.removeAllListeners();
}
async function getDeliveredAppNotifications() {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log('[APP] Delivered notifications: ', notificationList ? JSON.stringify(notificationList) : null);
}
async function removeDeliveredAppNotifications() {
  const notificationList = await PushNotifications.removeAllDeliveredNotifications();
  console.log('[APP] Removed notifications: ', notificationList ? JSON.stringify(notificationList) : null);
}
async function addAppListeners(ctx, jwtToken) {
  console.info('[APP] start listeners');
  await PushNotifications.addListener('registration', async (token) => {
    console.info('[APP] Registration token: ', token.value);
    setPushToken(ctx, token.value);
    await sendRegister(ctx, token.value, jwtToken);
    await getDeliveredAppNotifications();
    await removeDeliveredAppNotifications();
  });

  await PushNotifications.addListener('pushNotificationReceived', notification => {
    console.log('[APP] Message received: ', JSON.stringify(notification));
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', action => {
    console.log('[APP] Message action performed: ', JSON.stringify(action.notification));
    const objectId = action.notification.data.object_id;
    // Vue.prototype.$bus.$emit('widget-open-chat', objectId);
    ctx.app.router.push({ name: 'index', query: {chatId: objectId} });
    // ctx.app.router.replace({ ...ctx.$route, query: {...ctx.$route.query, chatId: objectId} });
  });
  console.info('[APP] end listeners');
}
async function unregisterAppNotifications(ctx) {
  await PushNotifications.unregister();
  setPushToken(ctx, '');
}
async function registerAppNotifications() {
  console.info('[APP] start register');
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('[APP] User denied permissions!');
  }

  await PushNotifications.register();

  if (Capacitor.getPlatform() === 'android') {
    await PushNotifications.createChannel({
      id: 'rkg_dev_channel_1',
      name: 'dev channel',
      importance: 5,
      visibility: 1,
      sound: 'ringtone.wav',
      lights: true,
      vibration: true,
    });
    console.log('[APP] Channel created:');
  }
  console.info('[APP] end register');
}
function addWebListeners() {
  try {
    onMessage(messaging, (payload) => {
      if (!payload.notification) return;
      console.info('[WEB] Message received: ', payload);

      const {title, body, icon} = payload.notification;
      const notificationOptions = {
        body,
        icon,
        image: icon,
      };
      const objectId = payload.data.object_id;
      const notification = new Notification(title, notificationOptions);
      notification.addEventListener('click', () => {
        Vue.prototype.$bus.$emit('widget-open-chat', objectId, true);
        notification.close();
      });
    });
  } catch (e) {
    console.error('[WEB] Error : ', e);
  }
  console.log('[WEB]: listeners added');
}


// WEB
async function requestWebPermission(ctx) {
  try {
    const permission = await Notification.requestPermission();
    console.log('[WEB] Permission given:', permission);
    // eslint-disable-next-line no-restricted-globals
    if (!ctx.store.getters.isMobile && (permission === 'default' || permission === 'denied')) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Вы запретили отображение уведомлений. Пожалуйста, разрешите их, чтобы не пропустить сообщения по зявкам. При нажатии кнопки "Да", откроется инструкция как это сделать')) {
        window.open('https://agent.razlet.kg/notifications-guide');
      }
    }
  } catch (e) {
    console.error('[WEB] Error : ', e);
  }
}
async function unregisterWebNotifications(ctx) {
  try {
    const token = await deleteToken(messaging);
    setPushToken(ctx, '');
    console.log('[WEB] Registration token deleted: ', token);
  } catch (e) {
    console.error('[WEB] Error : ', e);
  }
}
async function registerWebNotifications(ctx, jwtToken) {
  console.log('[WEB] register notifications');
  await requestWebPermission(ctx);

  try {
    const token = await getToken(messaging, {  vapidKey: 'BNGdAcOil5JsdQkSe_zAwEN6nv7oGNYQ3qeMbVt3lib8Fuqyn_DuBJ-n8ABg_sHA9QiQ9caUoX_q8ngYc7UlVlk' });
    console.log('[WEB] Registration token: ', token);
    if (token) {
      setPushToken(ctx, token);
      await sendRegister(ctx, token, jwtToken);
    }
  } catch (e) {
    console.error('[WEB] Error : ', e);
  }
}


// INIT
async function initAppPushNotifications(ctx, jwtToken) {
  console.info('[APP] init app start');
  await addAppListeners(ctx, jwtToken);
  console.info('[APP] init app start 1');
  await registerAppNotifications();
  console.info('[APP] init app star 2');
}
async function initWebPushNotifications(ctx, jwtToken) {
  await registerWebNotifications(ctx, jwtToken);
  addWebListeners(ctx);
}
async function initPushNotifications(ctx, jwtToken) {
  if (Capacitor.isNativePlatform()) {
    console.log('platform is APP');
    await initAppPushNotifications(ctx, jwtToken);
  } else {
    console.log('platform is WEB');
    await initWebPushNotifications(ctx, jwtToken);
  }
}


// STOP
async function stopAppPushNotifications(ctx) {
  await removeAppListeners(ctx);
  await unregisterAppNotifications(ctx);
}
async function stopWebPushNotifications(ctx) {
  await unregisterWebNotifications(ctx);
}
async function stopPushNotifications(ctx) {
  if (Capacitor.isNativePlatform()) {
    await stopAppPushNotifications(ctx);
  } else {
    await stopWebPushNotifications(ctx);
  }
}

export default async (ctx) => {
  Vue.prototype.$bus.$on('init-notifications', async (jwtToken) => {
    console.log('[WEB] [APP] init notifications');
    await initPushNotifications(ctx, jwtToken);
  });
  Vue.prototype.$bus.$on('reinit-notifications', async (jwtToken) => {
    console.log('[WEB] [APP] reinit notifications');
    await sendRegister(ctx, ctx.store.state.pushToken, jwtToken);
  });
  Vue.prototype.$bus.$on('stop-notifications', async () => {
    console.log('[WEB] [APP] stop notifications');
    await stopPushNotifications(ctx);
  });
};