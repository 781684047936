import { initializeApp, getApps } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const config = {
  apiKey: 'AIzaSyCDcnKkDI4qxO__cYIJBqwR2k5Z9E0v4L8',
  authDomain: 'web-razlet-kg.firebaseapp.com',
  databaseURL: 'https://web-razlet-kg-default-rtdb.firebaseio.com',
  projectId: 'web-razlet-kg',
  storageBucket: 'web-razlet-kg.appspot.com',
  messagingSenderId: '894849892003',
  appId: '1:894849892003:web:3a3266e09419d83a3ec916',
  measurementId: 'G-6JTCVRRTNK',
};

const apps = getApps();
const firebaseApp = apps.length ? apps[0] : initializeApp(config);

let messaging;
try {
  messaging = getMessaging(firebaseApp);
} catch (err) {
  console.error('[WEB] [APP] Failed to initialize Firebase Messaging', err);
}

export { messaging };