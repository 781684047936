import Vue from 'vue';
import {mapGetters} from 'vuex';
import filters from 'razlet-sdk/lib/filters/base';
import {MOTIVATION_CONTRACT, BALANCE} from 'razlet-sdk/lib/store/auth/getters.type';

Vue.mixin({
  data() {
    return {
      isKg: true,
      kgPhone: '+996 (709) 63-33-33',
      globalMountKey: 1,
      managersContacts: [
        { name: 'Чолпон', phone: '+996 (702) 11-18-50', href: '996702111850' },
        { name: 'Назбийке', phone: '+996 (706) 11-18-50', href: '996706111850' },
        { name: 'Мираида', phone: '+996 (708) 11-18-50', href: '996708111850' },
        { name: 'Перизат', phone: '+996 (705) 11-18-50', href: '996705111850' },
        { name: 'Асема', phone: '+996 (709) 11-18-50', href: '996709111850' },
        { name: 'Нурайым', phone: '+996 (707) 11-18-50', href: '996707111850' },
      ],
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'isOffline', 'isInitialOffline', 'user', 'currentTheme']),
    ...mapGetters('auth', [BALANCE, MOTIVATION_CONTRACT]),
    isDev() {
      return !/\/\/api.razlet.ru/.test(process.env.asb3URL);
    },
    isPublic() {
      return process.env.type === 'public' || (this.isApp && !this.isUser); 
    },
    isApp() {
      return process.env.type === 'app'; 
    },
    isAgent() {
      return process.env.type === 'agent' || (this.isApp && this.isUser);
    },
    isSulu() {
      return process.env.type === 'sulu';
    },
    showLogin() {
      return process.env.auth !== 'none';
    },
    isUser() {
      return typeof this.$store.state.user !== 'undefined' && this.user.model && !!Object.keys(this.user.model).length;
    },
    currency() {
      return process.env.currency || '';
    },
    suluUrl() {
      return process.env.suluURL;
    },
    userCurrency() {
      return this.isUser ? this.user.model.currency : this.currency;
    },
    officeUrl() {
      return process.env.officeURL;
    },
    isCashier() {
      return this.isUser && this.user?.model?.roles?.includes('ROLE_CASHER');
    },
    isAdmin() {
      return this.isUser && this.user?.model?.roles?.includes('ROLE_ADMIN');
    },
    isParseDocumentAllowed() {
      return this.isCashier || this.isAdmin;
    },
    isBalanceLoaded() {
      return this.isUser && this.balance && typeof this.balance !== 'string';
    },
    motivationContractId() {
      return this.isUser ? Number(this.motivationContract) : null;
    },
    currentBonusStatus() {
      if (!this.isUser) return 'silver';
      return 'silver';
    },
  },
  mounted() {
    this.changeColorScheme();
  },
  methods: {
    changeColorScheme () {
      const root = document.getElementsByTagName('html')[0];
      root.className = this.currentTheme;
    },
    async shareCurrentUrl () {
      const origin = this.isUser ? 'https://agent.razlet.kg' : 'https://razlet.kg';
      await this.$shareUrl(origin + this.$route.fullPath);
    },
  },
});

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

const COUNTRIES = {
  RUB: 'ru',
  USD: 'en',
  'KGS': 'ru-KG',
  'KGS-kg': 'ru-KG',
  'KGS-ru': 'ru-KG',
  'KGS-en': 'ru-US',
};

const getKGCurrency = (currency) => `${currency}-${Vue.prototype.$locale}`;
const getCurrency = (currency) => currency === 'KGS' ? getKGCurrency(currency) : currency;

Vue.prototype.$localizeCurrency = (value, currency) => `${Vue.prototype.$localize.n(value, 'currency', COUNTRIES[getCurrency(currency)])}`;

const app = new Vue({});

const globalVars = {
  defaultSearch: 'FRU0000MOW1000es',
  showAgreement: process.env.type === 'public',
  newRequestsEnabled: process.env.type === 'agent',
  sendMetaMarkers: true,
  openError: {},
  isKg: true,
};

Object.keys(globalVars).forEach(key => {
  app[key] = globalVars[key];

  if (typeof Vue.prototype[`$${key}`] === 'undefined') {
    Object.defineProperty(Vue.prototype, `$${key}`, {
      get() {
        return app[key];
      },
      set(value) {
        app[key] = value;
      },
    });
  }
});

Vue.prototype.$isFalsy = (value) => value === null || typeof value === 'undefined' || Number.isNaN(value) || value === '';
Vue.prototype.$isObject = (value) => !Vue.prototype.$isFalsy(value) && typeof value === 'object' && !Array.isArray(value);
Vue.prototype.$isValidObject = (value) => Vue.prototype.$isObject(value) && Object.keys(value).length;
Vue.prototype.$isString = (value) => !Vue.prototype.$isFalsy(value) && typeof value === 'string';
